import { LinkTypes } from 'fitify-ui-landing/src/@types'

const SOCIAL_LINKS = {
  instagram: 'https://www.instagram.com/fitifyapp',
  facebook: 'https://www.facebook.com/fitifyapp',
  x: 'https://x.com/FitifyApps',
  spotify:
    'https://open.spotify.com/user/31k4mcjeljqiu4yg6pjp9940r?si=cdd453649e8a42b9',
}

const STATIC_LINKS = {
  careers: 'https://jobs.lever.co/FitifyWorkouts',
  becomeCoach:
    'https://jobs.lever.co/FitifyWorkouts/fb36d7d6-fa92-41a9-9c25-852272159071',
  gift: process.env.NEXT_PUBLIC_ONBOARDING_GIFT_URL,
  pressKit:
    'https://drive.google.com/drive/folders/1Uk1A2dlxCI7OqnMNuh_mNu76StXldJzN?usp=share_link',
}

const footerSocial: LinkTypes.Navigation[] = [
  {
    type: 'link',
    caption: 'Instagram',
    icon: 'instagram',
    link: SOCIAL_LINKS.instagram,
    link_type: '_blank',
    isHiddenOnMobile: false,
  },
  {
    type: 'link',
    caption: 'Facebook',
    icon: 'facebook',
    link: SOCIAL_LINKS.facebook,
    link_type: '_blank',
    isHiddenOnMobile: false,
  },
  {
    type: 'link',
    caption: 'X',
    icon: 'x-social',
    link: SOCIAL_LINKS.x,
    link_type: '_blank',
    isHiddenOnMobile: false,
  },
  {
    type: 'link',
    caption: 'Spotify',
    icon: 'spotify',
    link: SOCIAL_LINKS.spotify,
    link_type: '_blank',
    isHiddenOnMobile: false,
  },
]

const footerNavigation: LinkTypes.Navigation[] = [
  {
    type: 'link',
    caption: 'landing_contact_us',
    link: '/contact',
    isHiddenOnMobile: false,
  },
  {
    type: 'link',
    caption: 'landing_work_section_title_for_business',
    link: '/business',
    isHiddenOnMobile: false,
  },
  {
    type: 'link',
    caption: 'landing_careers',
    link: '/careers',
    link_type: '_blank',
    isHiddenOnMobile: false,
  },
  {
    type: 'link',
    caption: 'landing_press',
    link: STATIC_LINKS.pressKit,
    link_type: '_blank',
    isHiddenOnMobile: false,
  },
  {
    type: 'link',
    caption: 'landing_gift',
    link: STATIC_LINKS.gift,
    link_type: '_blank',
    isHiddenOnMobile: false,
    isOnboarding: true,
  },
]

const footerCopy: LinkTypes.Navigation[] = [
  {
    type: 'link',
    caption: 'landing_terms_of_service',
    link: '/terms',
    isHiddenOnMobile: false,
  },
  {
    type: 'link',
    caption: 'landing_privacy_policy',
    link: '/privacy-policy',
    isHiddenOnMobile: false,
  },
]

export { footerSocial, footerNavigation, footerCopy }
